<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div v-if="campaign.tipe_campaign == campaignType.QURBAN_PATUNGAN" class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">NOMINAL QURBAN</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="my-4 pr-5 grid grid-cols-10">
                    <img class="col-span-2 h-16 w-full rounded-md object-cover" :src="campaign.image">
                    <div class="ml-8 col-span-8">
                        <label class="mt-2 text-sm">
                            <i class="fa fa-money-bill-wave text-yellow-500" aria-hidden="true"></i> Rp.
                        </label>
                        <vue-mask 
                            id="inputAmount"
                            class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-right font-medium text-sm"
                            v-model="donation.amount"
                            mask="000.000.000.000.000.000.000.000.000.000"
                            :options="optionsVuewMask"
                            placeholder="0"
                            autofocus
                            @change="changeMinAmount"
                        ></vue-mask>
                    </div>
                </div>

                <div class="text-xs">Silakan masukan nominal qurban patungan Anda, minimal Rp. {{ formatPrice(minAmount) }}. Nominal yang diqurbankan hanya untuk 1 pequrban saja.</div>

                <div class="my-2">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-praying-hands text-yellow-500" aria-hidden="true"></i> Do'a
                    </label>
                    <textarea rows="3" v-model="donation.pray"
                        class="appearance-none w-full bg-gray-100 border border-gray-200 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-3 text-sm font-medium" 
                        placeholder="Tulis Do'a/Ucapan">
                    </textarea>
                </div>
            </div>
            <div v-else class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">JUMLAH PEQURBAN</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="my-4 pr-5 grid grid-cols-10">
                    <img class="col-span-2 h-16 w-full rounded-md object-cover" :src="campaign.image">
                    <div class="ml-4 grid grid-rows-2 gap-1">
                        <button @click="setCount('+')" :disabled="isDisabledCountButton" :class="isDisabledCountButton ? 'bg-gray-100' : 'bg-yellow-500 shadow-md'" class="h-8 w-8 rounded-full text-sm text-white font-bold focus:outline-none focus:bg-yellow-600">+</button>
                        <button @click="setCount('-')" :disabled="isDisabledCountButton" :class="isDisabledCountButton ? 'bg-gray-100' : 'bg-yellow-500 shadow-md'" class="h-8 w-8 rounded-full text-sm text-white font-bold focus:outline-none focus:bg-yellow-600">-</button>
                    </div>
                    <div class="col-span-2">
                        <input type="number" 
                        class="appearance-none pl-3.5 ml-6 md:ml-4 my-4 text-base font-semibold w-10 h-10 bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600"
                        v-model="donation.count" 
                        @change="changeCount"/>
                    </div>
                    <div class="col-span-5">
                        <div class="my-6 w-full h-5 inline-flex justify-end">
                            <div class="text-sm">Rp.&emsp;</div>
                            <div class="text-xl font-semibold text-yellow-500">{{ formatPrice(donation.amount) }}</div>
                        </div>
                    </div>
                </div>

                <div class="text-xs">Penentuan nominal jumlah pequrban di tentukan oleh pihak Yayasan Dompet Yatim & Dhuafa</div>

                <div class="my-2">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-praying-hands text-yellow-500" aria-hidden="true"></i> Do'a
                    </label>
                    <textarea rows="3" v-model="donation.pray"
                        class="appearance-none w-full bg-gray-100 border border-gray-200 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-3 text-sm font-medium" 
                        placeholder="Tulis Do'a/Ucapan">
                    </textarea>
                </div>
            </div>

        </div>

        <div v-if="!isLoggedIn" class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">MASUKKAN DATA DIRI ANDA</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="mb-2">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-address-card text-yellow-500" aria-hidden="true"></i> Nama Lengkap
                    </label>
                    <input type="text"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-sm font-medium"
                        placeholder="Nama Lengkap Anda" 
                        v-model="donaturs.name"
                        id="donaturName"/>
                </div>

                <div class="mb-4">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-mobile-alt text-yellow-500" aria-hidden="true"></i> Nomor telepon
                    </label>
                    <vue-mask 
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-sm font-medium"
                        v-model="donation.phoneNumber"
                        mask="0000-0000-00000"
                        placeholder="0812-1234-56789"
                    ></vue-mask>
                </div>

                <div class="mb-4">
                    <label class="mt-2 text-xs">
                        <i class="fa fa-envelope text-yellow-500" aria-hidden="true"></i> Email Anda
                    </label>
                    <input type="email"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-sm font-medium"
                        placeholder="Email" v-model="donaturs.email">
                </div>

            </div>

        </div>

        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div class="bg-white rounded-xl shadow-md p-5">
                <div class="text-base font-semibold">MASUKKAN DATA PEQURBAN</div>
                <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                <div class="mb-2">
                    <!-- <label class="mt-2 font-bold text-sm">
                        <i class="fa fa-address-card text-yellow-500" aria-hidden="true"></i> Nama Lengkap
                    </label> -->
                    <div class="flex flex-wrap justify-start space-x-1">
                        <div v-for="q in qurbans" :key="q">
                            <span
                                class="mb-1 px-4 py-1 rounded-full text-gray-500 border border-gray-300 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                                {{ q }}
                                <button class="bg-transparent hover focus:outline-none"
                                @click="deleteQurban(q)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                    class="w-2 ml-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                    <path fill="currentColor"
                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                    </path>
                                </svg>
                                </button>
                            </span>
                        </div>
                    </div>
                    <input type="text"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-sm font-medium"
                        placeholder="Nama Pequrban" 
                        @change="setQurbans"/>
                    <span class="text-xs italic text-gray-400">Contoh nama pequrban: Wiriawan Wiguna bin Wiguna</span>
                </div>
                <!-- <div class="mb-6">
                    <label class="container-custom-checkbox">
                        <span class="text-sm">
                            Masukan diri Anda sebagai pequrban
                        </span>
                        <input type="checkbox" @change="setAsQurbanToo($event)" true-value="1" false-value="0" id="checkAsQurban"/>
                        <span class="checkmark-custom-checkbox"></span>
                    </label>
                </div> -->

                <div class="mt-6 mb-2">
                    <label class="container-custom-checkbox">
                        <span class="text-sm">
                            Ingin mendapat sebagian daging qurban
                        </span>
                        <input type="checkbox" @change="setIsWantToMeat($event)" true-value="1" false-value="0"/>
                        <span class="checkmark-custom-checkbox"></span>
                    </label>
                </div>
                <div v-if="donation.isWantToMeat" class="mb-6">
                    <input type="text"
                        class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 text-sm font-medium"
                        placeholder="Bagian daging yang diinginkan" 
                        v-model="donation.wantMeat"
                        id="wantToMeatField"/>
                    <span class="text-xs italic text-gray-400">Contoh bagian yang diinginkan: paha, iga, dsb.</span>
                </div>

                <div v-if="!isLoggedIn">
                    <button @click="storeDonationNonLogin" class="bg-yellow-500 mt-4 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">LANJUT PEMBAYARAN</button>
                </div>
                <div v-else>
                    <button @click="storeDonation" class="bg-yellow-500 mt-4 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">LANJUT PEMBAYARAN</button>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

    // Ganti title
    document.title = "Form Pembayaran";
    
    //hook vue
    import { computed, reactive } from 'vue'
    
    //hook vuex
    import { useStore } from 'vuex'
    
    //hook vue router
    import { useRoute, useRouter } from 'vue-router'
    
    //hook Toast
    import { useToast } from "vue-toastification"
    import vueMask from 'vue-jquery-mask';
    import CampaignTypeEnum from '@/enums/CampaignTypeEnum.js'

    export default {
        components: {
            vueMask
        },
        computed: {
            isLoggedIn() {
                const store = useStore()
                console.log('ada gak => ', store.getters['auth/isLoggedIn'])
                return store.getters['auth/isLoggedIn'] == '' ? false : true
            },
            isDisabledCountButton() {
                const route = useRoute()
                console.log(route.params.qurbanCount)
                return route.params.qurbanCount == 7 ? true : false
            }
        },
        created() {
            window.scrollTo(0, 0)
        },
        setup() {

            //store vuex
            const store = useStore()

            //route
            const route = useRoute()

            //router
            const router = useRouter()

            //toast
            const toast = useToast()

            //digunakan untuk mendapatkan data detail campaign dari state "campaign" di module "campaign" Vuex
            const campaign = computed(() => {
                return store.state.campaign.campaign;
            });

            const campaignType = CampaignTypeEnum

            //state donation
            const donaturs = reactive({
                name: '',                      // <-- data nama donatur
                phoneNumber: '',               // <-- data email donatur
                email: '',                    // <-- data email donatur
            })

            const optionsVuewMask = {
                reverse: true
            }

            let qurbans = reactive([])
            const minAmount = route.params.nominal

            //state donation
            const donation = reactive({
                count: route.params.qurbanCount,
                isWantToMeat: false,
                amount: route.params.nominal,                      // <-- data nilai donasi
                pray: '',                       // <-- data kata-kata/doa
                campaignSlug: route.params.slug, // <-- data "slug" dari campaign
                wantMeat: ''
            })

            function setQurbans(value) {
                if (qurbans.length < donation.count) {
                    qurbans.push(value.target.value)
                }
                value.target.value = ''
            }

            function deleteQurban(element) {
                console.log(element)
                const index = qurbans.indexOf(element);
                if (index > -1) {
                    qurbans.splice(index, 1); // 2nd parameter means remove one item only
                }
                if (element == donaturs.name) {
                    document.getElementById("checkAsQurban").checked = false
                }
            }

            function setAsQurbanToo(e) {
                if (donaturs.name == '') {
                    if (e.target.checked) {
                        toast.error('Mohon isi terlebih dahulu Data diri Anda')
                        document.getElementById("donaturName").focus()
                        e.target.checked = false
                    }
                } else {
                    if (e.target.checked && qurbans.length < donation.count) {
                        qurbans.push(donaturs.name)
                    } else {
                        deleteQurban(donaturs.name)
                    }
                }
            }

            function setIsWantToMeat(e) {
                donation.isWantToMeat = e.target.checked
                console.log(donation.isWantToMeat)
            }

            function setAmount(value) {
                donation.amount = value
            }

            function setCount(value) {
                if (value == '+') {
                    donation.count += 1
                } else {
                    if (donation.count > 1) {
                        donation.count -= 1
                    }
                }
                changeCount()
            }

            function changeCount() {
                donation.amount = donation.count * parseInt(route.params.nominal)
                document.getElementById('checkAsQurban').checked = false
                qurbans.splice(0, qurbans.length)
            }

            function changeMinAmount() {
                if (minAmount > donation.amount) {
                    toast.error('Nominal qurban masih kurang dari batas minimal')
                }
            }

            function setOtherAmount() {
                if (donation.amount == 10000 || donation.amount == 50000 || donation.amount == 100000) {
                    donation.amount = ''
                }
                document.getElementById('inputAmount').focus()
            }

            //method store donation
            function storeDonation() {

                //check minimal donasi
                // if(donation.amount < 10000) {
                //     toast.error('Donasi Minimal Rp. 10.000')
                //     return false
                // }
                if (donation.isWantToMeat && donation.wantMeat == '') {
                    toast.error('Mohon isi bagian daging yang Anda inginkan')
                    document.getElementById('wantToMeatField').focus()
                    return false
                }


                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray
                let nama_pengkurban = qurbans.join(', ')
                let bagian_pengkurban = donation.wantMeat

                store.dispatch('donation/storeQurban', {
                    campaign_slug,
                    amount,
                    pray,
                    nama_pengkurban,
                    bagian_pengkurban
                })
                .then(() => {

                    toast.success('Transaksi Berhasil Dibuat!')

                    //redirect ke dashboard
                    router.push({name: 'donation.index'})

                })
                .catch(error => {
                    console.log(error)
                    toast.error(error)
                })
            }

            function validateEmail(email) {
                const re = /\S+@\S+\.\S+/;
                return re.test(email);
            }

            //method store donation non login
            function storeDonationNonLogin() {

                //check minimal donasi
                if (donaturs.name == "") {
                    toast.error('Nama harus di isi terlebih dahulu')
                    return false
                }

                if (donaturs.email == '') {
                    donaturs.email = "qurban@domyadhu.org"
                }
                
                if (validateEmail(donaturs.email) == false) {
                    toast.error('Isikan Email Anda dengan benar')
                    return false
                } 

                if (donation.isWantToMeat && donation.wantMeat == '') {
                    toast.error('Mohon isi bagian daging yang Anda inginkan')
                    document.getElementById('wantToMeatField').focus()
                    return false
                }

                
                let name = donaturs.name
                let phone_number = donaturs.phoneNumber
                let email = donaturs.email
                let campaign_slug = donation.campaignSlug
                let amount = donation.amount
                let pray = donation.pray
                let nama_pengkurban = qurbans.join(', ')
                let bagian_pengkurban = donation.wantMeat

                store.dispatch('donation/storeQurbanNon', {
                    name,
                    phone_number,
                    email,
                    campaign_slug,
                    amount,
                    pray,
                    nama_pengkurban,
                    bagian_pengkurban
                })
                .then(() => {
                    const donationsNonLogin = computed(() => {
                        return store.state.donation.donationsNonLogin
                    })
                    console.log("donationsNonLogin")
                    console.log(donationsNonLogin.value)
                    let snap_token = donationsNonLogin.value.snap_token

                    //redirect ke SNAP MIDTRANS
                    window.snap.pay(snap_token, {

                        onSuccess: function () {
                            router.push({name: 'campaign.index'})
                            toast.success('Transaksi Berhasil!')
                        },
                        onPending: function () {
                            router.push({name: 'campaign.index'})
                            toast.warning('Silakan melakukan pembayaran untuk menyelesaikan transaksi Anda')
                        },
                        onError: function () {
                            router.push({name: 'campaign.index'})
                            toast.error('Transaksi Gagal')
                        }
                    })

                }).catch(error => {
                    console.log(error)
                    toast.error(error)
                })
            }

            return {
                campaignType,
                optionsVuewMask,
                qurbans,
                minAmount,
                setQurbans,
                deleteQurban,
                setIsWantToMeat,
                campaign,
                donation,       // <-- state donation
                donaturs,       // <-- state donaturs
                setAmount,
                setCount,
                changeCount,
                changeMinAmount,
                setOtherAmount,
                setAsQurbanToo,
                storeDonation,  // <-- method storeDonation
                storeDonationNonLogin
            }

        }

    }
</script>

<style>

/* Customize the label (the container) */
.container-custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-custom-checkbox {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-custom-checkbox:hover input ~ .checkmark-custom-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox {
  background-color: rgba(245, 158, 11, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-custom-checkbox .checkmark-custom-checkbox:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

</style>